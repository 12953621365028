import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "hug-a-tree"
    }}><a parentName="h2" {...{
        "href": "/lessons/hug-a-tree/"
      }}>{`Hug a Tree`}</a></h2>
    <p>{`Start off with 5 minutes of Hug a Tree meditation.`}</p>
    <h2 {...{
      "id": "empty-stance-vs-bow-stance"
    }}>{`Empty Stance vs Bow Stance`}</h2>
    <p>{`Learn the Empty Stance, and how it’s different from the Bow Stance.`}</p>
    <h2 {...{
      "id": "footwork-stepping-backward"
    }}>{`Footwork: Stepping Backward`}</h2>
    <p>{`Stepping backward in the Empty Stance.`}</p>
    <h2 {...{
      "id": "arms-push-and-pull"
    }}>{`Arms: Push and Pull`}</h2>
    <p>{`One arm pushes forward while the other one pulls inward.`}</p>
    <h2 {...{
      "id": "posture-repel-monkey"
    }}>{`Posture: Repel Monkey`}</h2>
    <p>{`Putting all the above together into the taiji posture called Repel Monkey.`}</p>
    <p><a parentName="p" {...{
        "href": "/lessons"
      }}>{`Back to Lessons`}</a></p>
    <p><a parentName="p" {...{
        "href": "/lessons/lesson-3/"
      }}>{`Back to Lesson 3`}</a></p>
    <p><a parentName="p" {...{
        "href": "/lessons/lesson-5/"
      }}>{`On to Lesson 5`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      